#toast-container > div {
    width: 500px !important;
}

/* Absolute Center Spinner */
.loading {
    position: fixed;
    z-index: 10000;
    height: 150px;
    width: 150px;
    background: #e8d887;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

/* Transparent Overlay */
.loading:before {
    content: '';
    display: block;
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .6);

}
